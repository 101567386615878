// @ts-check

import { ref, computed } from 'vue';
import { getListingtypeSubcategories, getUsageProposals, getFacilities, getEnergyLabels } from '@common/clients/metaRepository.js';
import { getAccounts } from '@common/clients/accountRepository.js';
import { notifyAxiosToUser } from '@common/utils/notify.js';
import { defineStore } from 'pinia';
import { sortBy } from '@common/utils/sort.js';

const cachedListingtypeSubcategories = ref(null);
const cachedUsageProposals = ref(null);
const cachedFacilities = ref(null);
const cachedEnergyLabels = ref(null);
const cachedAccounts = ref(null);

const fetchOnce = (fn, cachedObj) => {
  if (cachedObj.value === null) {
    cachedObj.value = [];
    fn()
      .then(data => (cachedObj.value = data))
      .catch((err) => {
        notifyAxiosToUser('failedToRetrieve', '', err);
        cachedObj.value = [];
      });
  }

  return cachedObj.value;
};

export const useMetaStore = defineStore('meta', () => {
  /**
   * Same roles as we get from property-service/accountroles
   * Are used on add a user to a account or organization
   * Hardcoded as they are not changing.
   */
  const accountRoles = [
    { id: 1, name: 'Admin', label: 'Administrator' },
    { id: 2, name: 'Member', label: 'Bruger' },
    { id: 3, name: 'ReadOnly', label: 'Læser' },
  ];

  /**
   *
   * @param {number} id Id on the role that should be returned
   * @returns {{id: number, name: string} | undefined} Account role
   * ^ (it should never be undefined but to satisfy the type gods)
   */
  const getAccountRoleById = (id) => {
    return accountRoles.find(r => r.id === id);
  };

  /**
   * Returns list of accounttypes.
   * Same types as we get from /property-service/accounttypes
   * Hardcoded as they are not changing
   */
  const accountTypes = [
    { id: 1, name: 'Broker' },
    { id: 2, name: 'LightAdvertiser' },
    { id: 3, name: 'PremiumAdvertiser' },
    { id: 4, name: 'Statistics' },
    { id: 5, name: 'Municipality' },
  ];

  /**
   *
   * @param {number} id Id on the type that should be returned
   * @returns {{id: number, name: string} | undefined} Accounttype
   */
  const getAccountTypeById = (id) => {
    return accountTypes.find(t => t.id === id);
  };

  /**
   * ListingtypeSubcategories, resonse is cached
   * @type {import('vue').Ref<{ID: number, name: string}[]>}
   */
  const listingtypeSubcategories = computed(() => fetchOnce(getListingtypeSubcategories, cachedListingtypeSubcategories).sort(sortBy('name')));

  /**
   * usageProposals, resonse is cached
   * @type {import('vue').Ref<{ID: number, name: string}[]>}
   */
  const usageProposals = computed(() => fetchOnce(getUsageProposals, cachedUsageProposals).sort(sortBy('name')));

  /**
   * facilities, resonse is cached
   * @type {import('vue').Ref<{ID: number, name: string}[]>}
   */
  const facilities = computed(() => fetchOnce(getFacilities, cachedFacilities).sort(sortBy('name')));

  /**
   * energyLabels, resonse is cached
   * @type {import('vue').Ref<{ID: number, name: string}[]>}
   */
  const energyLabels = computed(() => fetchOnce(getEnergyLabels, cachedEnergyLabels).sort(sortBy('name')));

  /**
   * accounts, resonse is cached
   * TODO: Maybe this one should not be here in meta. If we get like 1000 accounts, this will be a big one and should
   * have some kind of pagination
   * @type {import('vue').Ref<import('@common/clients/accountRepository.js').GetAccountsModel[]>}
   */
  const accounts = computed(() => fetchOnce(getAccounts, cachedAccounts).sort(sortBy('name')));

  return {
    accountRoles,
    accountTypes,
    getAccountRoleById,
    getAccountTypeById,
    listingtypeSubcategories,
    usageProposals,
    facilities,
    energyLabels,
    accounts,
  };
});
