export const da = {
  // API part is for propertyServiceClient.js
  // this part should take over for global.errors/messages/ressources and messages.js file
  api: {
    closingReasons: {
      concluded: 'Udlejet / Solgt',
      discontinued: 'Udgår af anden årsag',
      discontinuedareachange: 'Arealet på addressen er ændret, så annoncen udgår',
      discontinuedendofcommission: 'Komissionsaftale er ophørt/opsagt',
    },
    failedToRetrieve: '{entity} kunne ikke hentes',
    failedToAdd: '{entity} kunne ikke tilføjes',
    failedToDelete: '{entity} kunne ikke slettes',
    failedToUpdate: '{entity} kunne ikke opdateres',
    failedToActivate: '{entity} kunne ikke aktiveres',
    failedToDeactivate: '{entity} kunne ikke deaktiveres',
    failedToOpen: '{entity} kunne ikke åbnes',
    failedToClose: '{entity} kunne ikke lukkes',
    successfullyAdded: '{entity} er tilføjet',
    successfullyUpdated: '{entity} er opdateret',
    successfullyDeleted: '{entity} er slettet',
    successfullyActivated: '{entity} er aktiveret',
    successfullyDeactivated: '{entity} er deaktiveret',
    successfullyOpened: '{entity} er åbnet',
    successfullyClosed: '{entity} er lukket',
    successfullySentPasswordReset: `Adgangskode gendannelse afsendt`,
    entity: {
      account: 'Konto',
      accounts: 'Konti',
      accountTypes: 'Konto typer',
      accountRoles: 'Konto roller',
      accountUser: 'Konto bruger',
      accountListingSupplier: 'Konto for annonceleverandør',
      accountPermissions: 'Konto rettigheder',
      user: 'Bruger',
      users: 'Brugere',
      listings: 'Annoncer',
      listingSupplier: 'Annonceleverandør',
      listingSuppliers: 'Annonceleverandører',
      organization: 'Organisation',
      organizationUser: 'Organisation bruger',
      organizationUsers: 'Organisation brugere',
      rentalListing: 'Leje',
      rentalListings: 'Leje',
      investmentListing: 'Investering',
      investmentListings: 'Investeringer',
      salesListing: 'Salg',
      salesListings: 'Salg',
      officespaceListing: 'Kontorhotel',
      officespaceListings: 'Kontorhoteller',
      closingReasons: 'Lukke årsager',
      image: 'Billede',
      userEmails: 'Bruger emails',
      searchAgent: 'Søgeagent',
      apiLogs: 'API logs',
      password: 'Adgangskode',
      statistics: 'Statistik',
      favoritesList: 'Favoritter',
    },
    errors: {
      somethingWentWrong: 'Noget gik galt. Kontakt vores support afdeling hvis problemet fortsætter',
      noResponseFromServer: 'Intet svar fra serveren. Sikre at du har internetforbindelse og prøv igen',
      notLoggedOn: 'Du er ikke logget på',
      endpointNotFound: 'Endpoint ikke fundet ({endpoint})',
      requestCancelled: 'Anmodning annulleret',
    },
    sentPasswordReset: 'Sendte password gendannelse - Logger ud',
    failedSentPasswordReset: 'Fejlede password gendannelse - Logger ud',
    deleteUserConfirmed: 'Sletter din profil - Logger ud',
    deleteUserFailed: 'Sletning kunne ikke foretages - en fejl opstod',
  },
  global: {
    noData: 'Ingen data',
    noResults: 'Ingen resultater',
    errors: {
      required: '{label} er krævet',
      valid: '{label} skal være korrekt',
      mustBePostive: '{label} skal være positivt',
      between: '{label} skal være mellem {min} og {max}',
      lengthBetween: '{label} skal være mellem {min} og {max} tegn lang',
      minLength: '{label} skal være mindst {min} tegn lang',
      maxLength: '{label} må maks være {max} tegn lang',
      _: '{label}', // TODO: Only used for when the label is the error (rare cases)
    },
    messages: {
      activate: 'Aktivere',
      deactivate: 'Deaktivere',
      activated: 'Aktiveret',
      deactivated: 'Deaktiveret',
      closed: 'Afmeldt',
      close: 'Afmeld',
      opened: 'Aktiveret',
      open: 'Genaktiver',
      yes: 'Ja',
      no: 'Nej',
      login: 'Log ind',
      logout: 'Log ud',
    },
    form: {
      save: 'Gem',
      submit: 'Tilføj',
      update: 'Opdater',
      reset: 'Fortryd',
      delete: 'Slet',
      edit: 'Rediger',
      ok: 'Ok',
      close: 'Afmeld',
      deactivate: 'Deaktiver',
      reclose: 'Ret afmelding',
      open: 'Genaktiver',
      remove: 'Fjern',
      filter: 'Filter',
      search: 'Søg',
      list: 'Liste',
      sort: 'Sorter',
      copy: 'Kopier',
    },
    datePicker: {
      selectDate: 'Vælg dato',
    },
    status: {
      1: 'Kladde / Afventer', // Pending
      2: 'Aktiv', // Active
      3: 'Deaktiveret', // Inactive
      4: 'Karantæne', // Quarantine
      5: 'Afmeldt', // Closed
      6: 'Arkiveret', // Archived
      7: 'Slettet', // Deleted
    },
  },

  common: {
    address: {
      term: 'Adresse | Adresser',
      street: 'Vej',
      streetName: 'Vejnavn',
      streetNumber: 'Husnummer',
      floor: 'Etage',
      floorSide: 'Side',
      zipCode: 'Postnummer | Postnumre',
      city: 'By | Byer',
    },
    account: {
      term: 'Konto | Konti',
      statistics: 'Annoncestatistik | Annoncestatistik',
      type: 'Kontotype',
    },
    listing: {
      term: 'Annonce | Annoncer',
    },
    unsubscription: {
      term: 'Afmeldinger',
    },
    investmentListing: {
      term: 'Investering | Investeringer',
    },
    listingSupplier: {
      term: 'Annonceleverandør | Annonceleverandører',
    },
    organization: {
      term: 'Organisation | Organisationer',
    },
    rentalListing: {
      term: 'Leje | Leje',
    },
    salesListing: {
      term: 'Salg | Salg',
    },
    officespaceListing: {
      term: 'Kontorhotel | Kontorhoteller',
    },
    user: {
      term: 'Bruger | Brugere',
    },
    listingType: {
      term: 'Emne type',
    },
    role: 'Rolle | Roller',
    listingtypeSubcategory: 'Lokaletype | Lokaletyper',
    energyLabel: 'Energimærke | Energimærker',
    interval: 'Interval | Intervaller',
    usageProposal: 'Anvendelseforslag | Anvendelsesforslag',
    secondaryUsageProposals: 'Sekundære anvendelsesforslag | Sekundære anvendelsesforslag',
    facility: 'Facilitet | Faciliteter',
    authId: 'Auth ID',
    email: 'Email',
    phoneNumber: 'Telefonnummer',
    website: 'Website',
    name: 'Navn',
    id: 'Id',
    flashline: 'Flashline / kort overskrift',
    title: 'Introtekst / fremhævet under-overskrift',
    description: 'Beskrivelse',
    terms: 'Vilkår',
    advertisedAddress: 'Annonceringsadresse',
    url: 'URL',
    externalListingURL: 'Link til ejendom',
    image: 'Billede | Billeder',
    accountOwnerListingIdentifierID: 'Sagsnummer',
    shownAccountOwnerListingIdentifierID: 'Vist sagsnummer på annonce',
    floorArea: 'Etage areal',
    secondaryArea: 'Sekundært areal',
    groundArea: 'Grund areal',
    returnPercent: 'Afkast',
    salesPrice: 'Pris i alt kr.',
    yearlyOperatingCost: 'Årlige driftsudgifter',
    yearlyOperatingCostDKK: '@:common.common.yearlyOperatingCost @.lower:common.common.kr',
    yearlyRentalIncome: 'Årlig lejeindtægt. i alt kr.',
    yearlyOperatingCostPerSquareMeter: 'Årlig driftsudgift pr. @:common.common.m2 | Årlige driftsudgifter pr. @:common.common.m2',
    yearlyRentalIncludingOperatingCostPerSquareMeter: 'Årlig lejeindtægt inkl. drift pr. @:common.common.m2 | Årlige lejeindtægter inkl. drift pr. @:common.common.m2',
    yearlyRent: 'Årlig leje',
    yearlyRentIncludingOperatingCost: 'Årlig leje inkl. drift',
    yearlyRentIncludingOperatingCostPerSquareMeter: 'Årlig leje inkl. drift pr. @:common.common.m2',
    monthlyRentalIncludingOperatingCost: 'Md. leje inkl. drift',
    yearlyRentalIncomePerSquareMeter: 'Årlig lejeindt. pr. etage @:common.common.m2 @:common.common.kr',
    pricePerSquareMeter: 'Pris pr. @:common.common.m2',
    compensationPrice: 'Afståelsespris',
    constructionYear: 'Byggeår',
    billingId: {
      short: 'Fakt. nr',
      long: 'Fakturering nr (tidligere medlemsnummer)',
    },
    type: 'Type',
    kr: 'kr.',
    m2: 'm²',
    percent: '%',
    searchBy: 'Søg efter',
    noImages: 'Ingen billeder',
    noContact: 'Ingen kontaktinformation',
    status: 'Status',
    createdAt: 'Oprettet',
    project: 'Projekt',
  },

  // Components in components folder. Specific for the components
  component: {
    kindeError: {
      title: 'Fejl med brugeren',
      body: 'Der er sket en eller anden fejl ved authentication med Kinde, prøv at logge ud og på igen',
    },
    imageListingsForm: {
      labels: {
        addImage: 'Tilføj billede',
        addNewImage: 'Tilføj nyt billede',
        url: '@:common.common.url',
        enterUrl: 'Indtast URL',
        submitUrl: 'Tilføj url',
        selectImage: 'Vælg billede',
        submitImage: 'Tilføj billede',
      },
      messages: {
        newImageAdded: 'Nyt billede er blevet tilføjet',
        imageAlreadyExists: 'Billedet med denne URL eksisterer allerede. Prøv med en anden URL',
        confirmImageDeletion: 'Er du sikker på at du vil slette dette billede?',
        enterValidUrl: 'Indtast venligst en gyldig URL',
        enterImageUrl: 'Venligst angiv url til billede',
        selectImage: 'Vælg venligst et billede',
      },
    },

    listingForm: {
      charactersLeft: '{characters} tegn tilbage',
      charactersMissing: 'Mangler {characters} tegn',
      accountTitel: 'Vælg konto',
      imageTitel: 'Upload billeder',
      imageInfo: 'TIP: Minimum 3 billeder, maks. 10. Billeder skal være i bredformat (liggende) og mindst have opløsningen 800X600 pixels. Coverbillede må ikke være plantegning eller indholde tekst. Udskiftning af billeder kan tage op til 30 minutter, før at ændringen slår igennem på portalen.',
      addressTitel: 'Adresse',
      energyLabelTitel: 'Energimærke',
      listingsInfo: 'Sagsoplysninger',
      descriptionTitel: 'Beskrivelse',
      detailsTitel: 'Detaljer',
      labels: {
        accountOwnerListingIdentifierID: '@:common.common.accountOwnerListingIdentifierID',
        shownAccountOwnerListingIdentifierID: '@:common.common.shownAccountOwnerListingIdentifierID',
        images: 'Billeder',
        address: '@:common.common.address.term',
        title: '@:common.common.title (20-128 tegn)',
        flashline: '@:common.common.flashline (10-60-tegn)',
        description: '@:common.common.description (min. 100 tegn)',
        terms: '@:common.common.terms (valgfrit)',
        floorArea: '@:common.common.floorArea {\'(\'}@.lower:common.common.m2{\')\'}',
        secondaryArea: '@:common.common.secondaryArea {\'(\'}@.lower:common.common.m2{\')\'}',
        groundArea: '@:common.common.groundArea {\'(\'}@.lower:common.common.m2{\')\'}',
        account: 'Vælg @.lower:common.common.account.term',
        listingtypeSubcategory: '@:common.common.listingtypeSubcategory',
        listingtypeSubcategorySelect: 'Vælg en @.lower:common.common.listingtypeSubcategory',
        energyLabel: '@:common.common.energyLabel',
        energyLabelSelect: 'Vælg et eller flere @.lower:common.common.energyLabel',
        facility: '@:common.common.facility',
        facilitySelect: 'Vælg en eller flere @.lower:common.common.facility',
        usageProposal: '@:common.common.usageProposal',
        secondaryUsageProposals: '@:common.common.secondaryUsageProposals',
        usageProposalSelect: 'Vælg et eller flere @.lower:common.common.usageProposal',
        externalListingURL: '@:common.common.externalListingURL (maks. 300 tegn)',
        constructionYear: '@:common.common.constructionYear',
        contactEmail: 'Kontakt email',
        contactPhoneNumber: 'Kontakt nummer',
        yearlyRent: 'Årlig leje {\'(\'}@.lower:common.common.kr{\')\'}',
        valueAddedTax: 'Tilføjet skatteværdi',
        yearlyOperatingCost: '@:common.common.yearlyOperatingCost {\'(\'}@.lower:common.common.kr{\')\'}',
        compensation: 'Afståelsespris {\'(\'}@.lower:common.common.kr{\')\'}',
        project: '@:common.common.project',
        salesPrice: '@:common.common.salesPrice {\'(\'}@.lower:common.common.kr{\')\'}',
        yearlyRentalIncome: '@:common.common.yearlyRentalIncome {\'(\'}@.lower:common.common.kr{\')\'}',
        returnPercent: '@:common.common.returnPercent {\'(\'}@.lower:common.common.percent{\')\'}',
        fixedSeating: 'Fixed seating',
        flexibleSeating: 'Flexible seating',
        officeSeating: 'Office seating',
        openinghours: 'Vælg åbningstid',
        seatsMin: 'Min antal pladser',
        seatsMax: 'Maks antal pladser',
        priceMin: 'Min pris',
        priceMax: 'Maks pris',
        sqMMin: 'Min m2',
        sqMMax: 'Maks m2',
        tenderProcedure: 'Udbud/Licitation',
        depreciation: 'Afskrivning',
      },
    },

    listingShow: {
      title: {
        rental: 'Leje annonce',
        investment: 'Investerings annonce',
        officespace: 'Kontorhotel annonce',
        sales: 'Salgs annonce',
      },
      labels: {
        accountID: 'Kontonummer',
        accountOwnerListingIdentifierID: '@:common.common.accountOwnerListingIdentifierID',
        shownAccountOwnerListingIdentifierID: '@:common.common.shownAccountOwnerListingIdentifierID',
        constructionYear: '@:common.common.constructionYear',
        description: '@:common.common.description',
        externalListingURL: '@:common.common.externalListingURL',
        flashline: '@:common.common.flashline',
        title: '@:common.common.title',
        address: '@:common.common.address.term',
        contactInformation: 'Kontaktinformation',
        energyLabels: '@:common.common.energyLabel',
        facilities: '@:common.common.facility',
        listingTypeSubcategory: '@:common.common.listingtypeSubcategory',
        status: '@:common.common.status',
        usageProposals: '@:common.common.usageProposal',
        secondaryUsageProposals: '@:common.common.secondaryUsageProposals',
        companySale: 'Firmasalg',
        deferredTax: 'Udsættelsesskat',
        depreciation: 'Afskrivning',
        projectProperty: 'Projekt ejendom',
        returnPercent: 'Afkast',
        saleAndLeaseBack: 'Salg og tilbageleje',
        salesPriceDKK: 'Salgspris',
        tenderPriceDKK: 'Udbudspris',
        tenderProcedure: 'Udbudsprocedure',
        valueAddedTax: 'Tilføjet skatteværdi',
        yearlyOperatingCostDKK: 'Årlige driftsudgifter',
        yearlyRentalIncomeDKK: 'Årlige lejeindtægter',
        floorAreaM2: '@:common.common.floorArea',
        groundAreaM2: '@:common.common.groundArea',
        secondaryAreaM2: '@:common.common.secondaryArea',
        fixedSeating: 'Fixed seating',
        flexibleSeating: 'Flexible seating',
        officeSeating: 'Office seating',
        terms: '@:common.common.terms',
        compensationDKK: 'Kompensations leje',
        yearlyRentDKK: 'Årlig leje',
        noContact: '@:common.common.noContact',
      },
    },
  },
};
