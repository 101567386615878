<template>
  <EtNavbar>
    <template #title="{ closeMenu }">
      <RouterLink
        :to="{ name: routeNames.HomePage }"
        @click="closeMenu">
        <div class="et-header__logo">
          <svg
            width="48"
            height="32"
            viewBox="0 0 48 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M45.1366 14.6761L32.486 2.5755C32.486 2.5755 30.6984 0.61603 27.7076 0.134757C24.7168 -0.346517 22.5167 0.61603 22.5167 0.61603C22.5167 0.61603 5.36276 6.66633 4.22833 7.07885C3.0939 7.45699 0 9.17583 0 11.1697C0 12.7854 0.378145 13.0948 0.962548 13.9198C1.51258 14.7449 11.0005 28.5987 11.0005 28.5987C11.0005 28.5987 12.685 30.8675 15.882 31.7269C19.0447 32.5864 22.2417 31.1425 22.2417 31.1425L43.6928 22.3077C43.6928 22.3077 46.8554 20.8295 47.0617 18.6638C47.3023 16.4637 45.1366 14.6761 45.1366 14.6761Z"
              fill="#00D774" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M22.1301 22.1923C22.1301 22.6209 21.9694 22.7816 21.5408 22.7816V22.8351H14.6835C14.255 22.8351 14.0942 22.6744 14.0942 22.2458V9.87055C14.0942 9.44197 14.255 9.28125 14.6835 9.28125H21.3266C21.7551 9.28125 21.9159 9.3884 21.9159 9.81698V11.3706C21.9159 11.7992 21.7551 11.9599 21.3266 11.9599H17.3086V14.5314H21.0051C21.4337 14.5314 21.5944 14.6385 21.5944 15.0671V16.5136C21.5944 16.9421 21.4337 17.1029 21.0051 17.1029H17.3086V20.1029H21.5408C21.9694 20.1029 22.1301 20.2101 22.1301 20.6387V22.1923ZM32.309 11.9599C32.7375 11.9599 32.8983 11.7992 32.8983 11.3706H32.8447V9.81698C32.8447 9.44197 32.684 9.28125 32.2554 9.28125H24.0588C23.6302 9.28125 23.4695 9.3884 23.4695 9.81698V11.3706C23.4695 11.7992 23.6302 11.9599 24.0588 11.9599H26.5767V22.2458C26.5767 22.6744 26.7374 22.8351 27.166 22.8351H29.2553C29.6303 22.8351 29.791 22.6744 29.791 22.2458V11.9599H32.309Z"
              fill="white" />
          </svg>
          {{ $t('layout.theNavigationBar.title') }}
        </div>
      </RouterLink>
    </template>
    <template #nav1items>
      <EtNavbarItem>
        <a
          :href="wwwLink"
          target="_blank">
          {{ $t('layout.theNavigationBar.gotoEt') }}
        </a>
      </EtNavbarItem>
    </template>
    <template #nav2items="{ closeMenu }">
      <EtNavbarItem icon="add">
        <RouterLink
          v-if="userStore.isETAdmin"
          :to="{ name: routeNames.ListingsCreate }"
          @click="closeMenu">
          {{ $t('layout.theNavigationBar.newListing') }}
        </RouterLink>
      </EtNavbarItem>
      <EtNavbarItem icon="user">
        <a
          v-if="!userStore.isAuthenticated"
          @click="onLogin">
          {{ $t('layout.theNavigationBar.login') }}
        </a>
        <RouterLink
          v-else
          :to="{ name: routeNames.UserProfileIndex }"
          @click="closeMenu">
          {{ $t('layout.theNavigationBar.loggedOnAs', { email: userStore.user?.email }) }}
        </RouterLink>
      </EtNavbarItem>
    </template>
    <template
      v-if="!route.meta.public || userStore.isETAdmin"
      #nav3items>
      <RouterLink
        v-for="child in links"
        v-slot="{ href, navigate, isActive }"
        :key="child.label"
        :to="{ name: child.routeName }"
        custom>
        <EtNavbarItem
          :is-active="isActive"
          :href="href"
          @click="navigate">
          {{ child.label }}
        </EtNavbarItem>
      </RouterLink>
    </template>
  </EtNavbar>
</template>

<script setup>
  import { EtNavbar, EtNavbarItem } from 'vue-ui-kit';
  import { useAuth } from '@common/composables/auth/authPlugin.js';
  import { RouterLink, useRoute } from 'vue-router';
  import { routeNames } from '@/router';
  import { useT } from '@common/i18n/i18n.js';
  import { useUserStore } from '@common/store/index.js';
  const t = useT();
  const route = useRoute();
  const userStore = useUserStore();
  const { login } = useAuth();

  const onLogin = () => {
    login({ fullPath: '/' });
  };

  const wwwLink = import.meta.env.VITE_PATH_WWW;

  const links = [
    {
      label: t('layout.theNavigationBar.approval'),
      routeName: routeNames.HomePage,
    },
    {
      label: t('layout.theNavigationBar.listings', 2),
      routeName: routeNames.ListingsIndex,
    },
    {
      label: t('layout.theNavigationBar.users'),
      routeName: routeNames.UsersIndex,
    },
    {
      label: t('layout.theNavigationBar.organizations'),
      routeName: routeNames.OrganizationsIndex,
    },
    {
      label: t('layout.theNavigationBar.accounts'),
      routeName: routeNames.AccountsIndex,
    },
    {
      label: t('layout.theNavigationBar.listingSuppliers'),
      routeName: routeNames.ListingSuppliersIndex,
    },
    {
      label: 'Værktøjer',
      routeName: routeNames.ToolsIndex,
    },
  ];
</script>
<style scoped>
  .et-header__logo svg {
    margin-right: 18px;
  }
  :global(.et-header__logo-title) {
    margin-left: 0 !important;
  }
</style>
