<script setup>
  // @ts-check
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';
  import TheNavigationBar from '@/layout/TheNavigationBar.vue';
  import { EtLoader } from 'vue-ui-kit';
  import { useAuth } from '@common/composables/auth/authPlugin.js';

  const route = useRoute();
  const auth = useAuth();
  (async () => await auth.ready())();
  const isReady = computed(() => route.name != null && !auth.isLoading.value);
</script>

<template>
  <TheNavigationBar
    v-if="isReady" />
  <RouterView v-if="isReady" />
  <EtLoader
    :loading="!isReady && !auth.isLoading"
    is-page />
</template>
