import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { setupI18n } from '@common/i18n/i18n.js';
import { da } from '@/i18n/da.js';
import authPlugin from '@common/composables/auth/authPlugin.js';

import App from '@/App.vue';
import router from './router';
import * as Sentry from '@sentry/vue';

const app = createApp(App);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [import.meta.env.VITE_SENTRY_SITE_URL, /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

app.use(createPinia());
app.use(router);
app.use(setupI18n(da));

app.use(authPlugin, { serviceUri: import.meta.env.VITE_PATH_WWW, loginUri: import.meta.env.VITE_PATH_WWW + '/login', logoutUri: import.meta.env.VITE_PATH_WWW });

app.mount('#app');
