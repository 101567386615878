// @ts-check

/**
 * All calls to property service api should use this axios instance
 */

import axios from 'axios';
import { useAuth } from '@common/composables/auth/authPlugin.js';
import { handleAxiosReject } from '@common/clients/clientHelpers.js';

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_PROPERTY_SERVICE_API_URL,
});

axiosInstance.interceptors.request.use(async (config) => {
  const auth = useAuth();
  await auth.ready();
  config.headers.Authorization = `Bearer ${auth.getToken()}`;
  return config;
});

axiosInstance.interceptors.response.use((response) => {
  return response;
}, handleAxiosReject);

export default axiosInstance;
